.sd-logo {
  background-color: white;
  img {
    filter: invert(49%) sepia(92%) saturate(4000%) hue-rotate(359deg)
      brightness(101%) contrast(104%);
    height: 80px;
  }
}

.sd-title,
.sd-description,
.sd-item__control-label,
.sd-html,
.sd-progress__text,
.sd-btn,
.sv-string-viewer {
  font-family: Regular !important;
}

.sd-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  > span {
    color: #000;
    text-align: center;
  }
}

.textalignCenter {
  text-align: center;
}

#surveyElement {
  --primary: #ff5c00;
  --background: #ffffff;
  --background-dim: #f3f3f3;
  --background-dim-light: #f9f9f9;
  --primary-foreground: #ffffff;
  --foreground: #161616;
  --base-unit: 8px;
}

.Toastify__progress-bar-theme--dark {
  background: #ff5c00 !important;
}

.Toastify__toast-container--top-center {
  position: relative !important;
  display: block;
  margin: auto;
  top: auto !important;
  left: auto !important;
  transform: none !important;
  margin-top: 20px;
}

.s10logo {
  filter: invert(49%) sepia(92%) saturate(4000%) hue-rotate(359deg)
    brightness(101%) contrast(104%);
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  border-bottom: 2px solid #ff5c00;
  max-height: 20vh;
  flex-wrap: wrap;
  p {
    padding: 0px 10px;
  }
  img {
    max-height: 30px;
  }
}

.surveyContainer {
  display: block;
  margin: auto;
  text-align: center;
  h1 {
    margin: 20px 5px;
    line-height: 2.4rem;
  }
}

.sd-container-modern {
  border-top: solid 1px #ff5c00;
}

#error-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
