.introText {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-color: #052e5e;
  height: 100vh;
  color: white;
  padding: 20px;
  p {
    font-size: 1rem;
    max-width: 70vw;
  }
  button {
    margin-top: 20px;
    font-size: 1rem;
    transition: all 0.3s ease-in;
    cursor: pointer;
    background-color: #ff5c00;
    color: white;
    font-weight: bold;
    padding: 10px 50px;
    border-radius: 10px;
    border: 2px solid #ff5c00;
  }
  button:hover {
    text-decoration: underline;
  }
  .speedometerDiv {
    padding-top: 30px;
    min-width: 280px;
    min-height: calc(320px / 1.6);
    width: 33vw;
    height: calc(33vw / 1.6);
    text {
      font-size: 12px !important;
      fill: white !important;
    }
  }
}
